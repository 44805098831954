import { Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from './ListingRequests.module.scss';
import EditableWatchSummary from "./EditableWatchSummary";
import { loggingService } from "../../../../utils/logging/logging";
import { createDefaultListing, getItemStatusCountsForAdmin, getItemsForStatus, getListingForStatus, getListingStatusCountsForAdmin, itemApprove, requestListingApproval, updateItemDetails, updateListingComingSoonl, updateListingDetails, getProducts, itemReject, listingReject, listingWithdraw } from "../../../services/auction-service";
import { SnackContext } from "../../../providers/SnackProvider";
import { WatchListing } from "../../../models/WatchListing";
import BackDrop from "../../backDrop/BackDrop";
import { ProductGroups } from "../../../models/ProductInfo";

const {log} = loggingService('app:ListingRequests');
type StatusKey = 'new'|'drafting'|'pending'|'approved'|'comingsoon'|'live'|'inactive';
const defaultStatusToCountMap: Record<StatusKey, number> = {
    new: 0,
    drafting: 0,
    pending: 0,
    approved: 0,
    comingsoon: 0,
    live: 0,
    inactive: 0,
};

const hasItemDetailsChanged = (original: WatchListing, updated: WatchListing) => {
    return original.brand !== updated.brand ||
        original.model !== updated.model ||
        original.year !== updated.year ||
        original.summary !== updated.summary ||
        original.specification.modelNumber !== updated.specification.modelNumber ||
        original.specification.condition !== updated.specification.condition ||
        original.specification.box !== updated.specification.box ||
        original.specification.papers !== updated.specification.papers ||
        original.conditionDetails.description !== updated.conditionDetails.description;
}

const hasListingDetailsChanged = (original: WatchListing, updated: WatchListing) => {
    return original.reservePrice !== updated.reservePrice ||
        original.auctionStartDate !== updated.auctionStartDate ||
        original.auctionEndDate !== updated.auctionEndDate ||
        original.specification.lot !== updated.specification.lot;
}

const ListingRequests = () => {
    const showSnackbar = useContext(SnackContext);
    const [selectedOption, setSelectedOption] = useState<StatusKey>('new');
    const [statusToCountMap, setStatusToCountMap] = useState(defaultStatusToCountMap);
    const [isLoading, setIsLoading] = useState(false);
    const [listings, setListings] = useState<WatchListing[]>([]);
    const [brands, setBrands] = useState<any[]>([]);
    const [allProducts, setAllProducts] = useState<ProductGroups>({});
    const listingsRef = useRef(listings);
    listingsRef.current = listings;

    const handleMenuOptionClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, option: StatusKey) => {
        e.preventDefault();
        setSelectedOption(option);
    }, []);

    const loadStatusCounts = useCallback(async () => {
        try {
            const itemStatusCounts = await getItemStatusCountsForAdmin();
            const listingStatusCounts = await getListingStatusCountsForAdmin();
            const statusToCount: Record<StatusKey, number> = {
                new: itemStatusCounts.Proposal,
                drafting: listingStatusCounts.Draft,
                pending: listingStatusCounts.ApprovalRequested,
                approved: listingStatusCounts.Accepted,
                comingsoon: listingStatusCounts.ComingSoon,
                live: listingStatusCounts.Live,
                inactive: listingStatusCounts.EndedNotSold + listingStatusCounts.EndedSold + listingStatusCounts.Rejected + listingStatusCounts.Withdrawn,
            };
            setStatusToCountMap(statusToCount);
        } catch (error) {
            log('Error loading status count', error);
        }
    }, []);

    const loadWatchesForFilter = useCallback(async (statusFilter: StatusKey) => {
        log(`getting watches for status: ${statusFilter}`);
        setListings([]);
        
        let watchListings: WatchListing[] = [];

        switch (statusFilter) {
            case 'new' : {
                watchListings = await getItemsForStatus('Proposal');
                break;
            }
            case 'drafting': {
                watchListings = await getListingForStatus('Draft');
                break;
            }
            case 'pending' : {
                watchListings = await getListingForStatus('ApprovalRequested');
                break;
            }
            case 'approved' : {
                watchListings = await getListingForStatus('Accepted');
                break;
            }
            case 'comingsoon' : {
                watchListings = await getListingForStatus('ComingSoon');
                break;
            }
            case 'live' : {
                watchListings = await getListingForStatus('Live');
                break;
            }
            case 'inactive' : {
                watchListings = await getListingForStatus('EndedSold,EndedNotSold,Rejected,Withdrawn');
                break;
            }
        }
        
        log(`getting watches for status: ${statusFilter} result`, watchListings);

        // Sort the listings by the created time 
        watchListings.sort((x,y) => x.createdOn === y.createdOn ? 0 : (x.createdOn > y.createdOn ? 1 : -1));

        setStatusToCountMap(prev => {
            return {
                ...prev,
                [statusFilter]: watchListings.length
            }
        });
        
        setListings(watchListings);
    }, []); 

    const loadProducts = useCallback(async () => {
        try {
            const productsInfo = await getProducts();
            const data = Object.keys(productsInfo) as (keyof ProductGroups)[];
            setAllProducts(productsInfo);
            setBrands(data);
        } catch (error) {
            showSnackbar(
                "An error occurred loading the brand listings. Please try again later.",
                { alertSeverity: "error" },
            );
            setBrands([]);
        }
    }, [showSnackbar]);

    const refreshListings = useCallback(async () => {
        try {
            await loadStatusCounts();
            await loadWatchesForFilter(selectedOption);
        } catch (error) {
            log(`Error refreshing listing for ${selectedOption}`, error);
            showSnackbar('An error occurred refreshing watch listing. Please try again later.', {alertSeverity: 'error'});
        } finally {
            setIsLoading(false);
        }
    }, [loadStatusCounts, loadWatchesForFilter, selectedOption, showSnackbar]);

    useEffect(() => {
        refreshListings();
        loadProducts();
    }, [refreshListings, loadProducts]);

    const updateListing = useCallback(async (originalListing: WatchListing, updatedListing: WatchListing): Promise<boolean> => {
        try {
            setIsLoading(true);
            
            if (hasItemDetailsChanged(originalListing, updatedListing)) {
                await updateItemDetails(updatedListing);
            }

            if (hasListingDetailsChanged(originalListing, updatedListing)) {
                await updateListingDetails(updatedListing);
            }

            const index = listingsRef.current.findIndex(l => l.listingId === originalListing.listingId);

            if (index !== -1) {
                const cloneListings = [...listingsRef.current];
                cloneListings[index] = updatedListing;
                setListings(cloneListings);
            }

            return true;
        } catch (error) {
            log(`Error updating item ${originalListing.listingId}`, error);
            showSnackbar('An error occurred updating the listing. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }        
    }, [showSnackbar]);

    const updateListingImages = useCallback((updatedList: WatchListing) => {
        setListings((prev) => {
            return prev.map(item => {
                if(item.itemId === updatedList.itemId){
                    return updatedList
                } else return item
            });
        })
    }, []);

    const startDrafting = useCallback(async (listing: WatchListing): Promise<boolean> => {
        try {
            setIsLoading(true);
            log(`Setting item Id ${listing.itemId} to status accepted`);
            await itemApprove(listing.itemId);
            log(`Creating draft listing for item Id ${listing.itemId}`);
            const listingId = await createDefaultListing(listing.itemId);
            log(`Successfully created draft listing Id: ${listingId}`);
            
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating item ${listing.itemId} to start drafting`, error);
            showSnackbar('An error occurred updating the listing to start drafting. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }        
    }, [refreshListings, showSnackbar]);

    const rejectProposal = useCallback(async (listing: WatchListing): Promise<boolean> => {
        try {
            setIsLoading(true);
            log(`Setting item Id ${listing.itemId} to status rejected`);
            await itemReject(listing.itemId);
            log(`Successfully updated item Id ${listing.itemId} to status rejected`);
            
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating item ${listing.itemId} to rejected`, error);
            showSnackbar('An error occurred rejecting the proposal. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }        
    }, [refreshListings, showSnackbar]);

    const rejectDraft = useCallback(async (listing: WatchListing): Promise<boolean> => {
        if (!listing.listingId) {
            showSnackbar('The listing cannot be rejected, a listing Id has not been specified', {alertSeverity: 'error'});
            return false;
        }

        try {
            setIsLoading(true);
            log(`Setting listing Id ${listing.listingId} to status rejected`);
            await listingReject(listing.listingId);
            log(`Successfully updated listing Id ${listing.listingId} to status rejected`);
            
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating listing ${listing.listingId} to rejected`, error);
            showSnackbar('An error occurred rejecting the draft listing. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }        
    }, [refreshListings, showSnackbar]);

    const withdrawListing = useCallback(async (listing: WatchListing, reason: string): Promise<boolean> => {
        if (!listing.listingId) {
            showSnackbar('The listing cannot be withdraw, a listing Id has not been specified', {alertSeverity: 'error'});
            return false;
        }

        try {
            setIsLoading(true);
            log(`Setting listing Id ${listing.listingId} to status withdrawn`);
            await listingWithdraw(listing.listingId, reason);
            log(`Successfully updated listing Id ${listing.listingId} to status withdrawn`);
            
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating listing ${listing.listingId} to withdrawn`, error);
            showSnackbar('An error occurred withdrawing the listing. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }        
    }, [refreshListings, showSnackbar]);    

    const requestApproval = useCallback(async (listing: WatchListing): Promise<boolean> => {
        if (!listing.listingId) {
            showSnackbar('Cannot request approval for selected listing as Listing Id is missing', {alertSeverity: 'error'});
            return false;
        }

        try {
            setIsLoading(true);
            await requestListingApproval(listing.listingId);
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating item ${listing.itemId} for requesting approval`, error);
            showSnackbar('An error occurred updating the listing for requesting approval. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [refreshListings, showSnackbar]);

    const updateToComingSoon = useCallback(async (listing: WatchListing): Promise<boolean> => {
        if (!listing.listingId) {
            showSnackbar('Cannot update to Coming Soon for selected listing as Listing Id is missing', {alertSeverity: 'error'});
            return false;
        }

        try {
            setIsLoading(true);
            await updateListingComingSoonl(listing.listingId);
            await refreshListings();
            return true;
        } catch (error) {
            log(`Error updating item ${listing.itemId} for Coming Soon`, error);
            showSnackbar('An error occurred updating the listing for Coming Soon. Please try again.', {alertSeverity: 'error'});
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [refreshListings, showSnackbar]);

    return (
        <div className={styles.root}>
            <Stack direction='column' style={{gridColumn: 1}} className={styles.leftContainer}>
                <Stack direction='row' className={selectedOption === 'new' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'new')}>New Requests</a>
                    </Typography>
                    <Stack direction='row' className={styles.newRequestsContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.new ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'drafting' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'drafting')}>Drafting</a>
                    </Typography>
                    <Stack direction='row' className={styles.draftingContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.drafting ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'pending' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'pending')}>Pending Approval</a>
                    </Typography>
                    <Stack direction='row' className={styles.pendingContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.pending ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'approved' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'approved')}>Ready For Auction</a>
                    </Typography>
                    <Stack direction='row' className={styles.approvedContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.approved ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'comingsoon' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'comingsoon')}>Coming Soon</a>
                    </Typography>
                    <Stack direction='row' className={styles.comingSoonContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.comingsoon ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'live' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'live')}>Live</a>
                    </Typography>
                    <Stack direction='row' className={styles.liveContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.live ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'inactive' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'inactive')}>Archived Listings</a>
                    </Typography>
                    <Stack direction='row' className={styles.inactiveContainer}>
                        <Typography className={styles.countLabel}>{statusToCountMap.inactive ?? 0}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction='column' style={{gridColumn: 2}}>
                {listings.length ?
                    listings.map(listing =>(
                        <EditableWatchSummary 
                            key={listing.itemId}
                            watchDetails={listing}
                            updateListing={updateListing}
                            updateListingImages={updateListingImages}
                            startDrafting={startDrafting}
                            rejectProposal={rejectProposal}
                            rejectDraft={rejectDraft}
                            withdrawListing={withdrawListing}
                            requestApproval={requestApproval}
                            updateToComingSoon={updateToComingSoon}
                            refreshListings={refreshListings}
                            brands={brands}
                            allProducts={allProducts}
                        />
                    )) : (
                        <Stack key='No watches found' className={styles.noItemsContainer} direction='column'>
                            <Typography className={styles.noItemsLabel}>No watches found.</Typography>
                        </Stack>
                    )
                }
            </Stack>

            <BackDrop open={isLoading} />
        </div>
    );
}

export default ListingRequests;